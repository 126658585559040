import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchExternalClientDetails,
} from "../../../redux/fui/index";
import { checkRootExisted } from "../../../redux/fui";
import { StyledTooltip, StyledParagraphBold, TextWrapper } from "../FuiStyles";
import { longTextEndDate, rootExistedHint, rootWrongFormatHint } from "../Constants/LongText";
import { useDebounce } from "../../../helper/customizedHooks";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import { checkServiceNowTicket, checkWBSECode } from "./CommonFunctions";
import GeneralForm from "./GeneralForm";
import * as fuiRedux from "../../../redux/fui/index";
import { Grid } from "@material-ui/core";
// import { appNameProjectId } from "../Constants/ProductIds";
import { Typography } from "@material-ui/core";

/**
 * Automatic Ticket Resolver Form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */

const AutomaticTicketResolver = ({
  values, // the value would be changed by user's input
  updateATRForm, //the function to update DPForm values which is from redux
  createdBy, // user's EID
  checkDomainAvaliable, // the function that check whether DomainContextRoot exist or not from redux
  isRootExist, // the value that show whether DomainContextRoot exist or not from redux
  setIsAllValid, // the function to check whether all validations are satisfieds
  selectedEnvironment,
  minDate,
  maxDate,
  isServiceEndDateInvalid,
  getSelectedEnvironment,
  getServiceEndDate,
  getNotesValue,
  notesValidations,
  configurationType,
  classes,
  history,
  email,
  idToken,
  isExternalClientLoading,
  externalClientData,
  externalClientError,
  fetchExternalClientDetails,
  isLoading,
}) => {
  // open the dialog when user choose Yes in GDPR
  const config = configurationType === "quick";

  //check whether ServiceNowTicket is valid
  const [isServiceNowTicketValid, setIsServiceNowTicketValid] = useState(true);

  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isDialogFlowDevToken, setIsDialogFlowDevToken] = useState(false);
  const [isDialogFlowClientToken, setIsDialogFlowClientToken] = useState(false);
  //   const [isUsersFieldValid, setUsersField] = useState(false);
  const isGDPR = values.GDPR === "yes";
  const isOpenAI = values.OpenAI === "True";
  const isEnableQuasar = values.EnableQuasar === "True";
  const isDedicated = values.InstanceType === history.location.state.InstanceType;
  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  const isDialogFlow = values.DialogFlow === "enabled";
  //   const isPaymentRate = values.PaymentRate === "payasyougo";
  const instanceType = history.location.state.InstanceType;

  const [sapContractCodeLength, setSapContractCodeLength] = useState("0");
  const [domainContextRoot, setDomainContextRoot] = useState("[__]");
  const [regionCode, setRegionCode] = useState("[__]");
  const [errorADGroup, setErrorADGroup] = useState(false);
  const [errorSADGroup, setErrorSADGroup] = useState(false);
  //check if Admin Distribution Group & Super Admin Distribution Group same
  const [isADSADUnique, setIsADSADUnique] = useState(false);

  const [gptVersion, setGptVersion] = useState("gpt-35-turbo-16k");
  const [customURL, setCustomURL] = useState("https://[__].mywizard-aiops.accenture.com/[__]");

  useEffect(() => {
    if (config) {
      setDomainContextRoot("[__]");
      setRegionCode("[__]");
    }
  }, [config]);


  if (config) {
    updateATRForm("ClientName", "")
    updateATRForm("EndToEndUId", "")
    updateATRForm("Company", "")
    updateATRForm("AvanadeCountry", "")
    updateATRForm("AccentureCountry", "")
    // updateATRForm("ChatGPT", "gpt-4")
    updateATRForm("InstanceType", history.location.state.InstanceType)
    updateATRForm("CAMPortalEnvironment", history.location.state.CAMPortalEnvironment)
    // updateATRForm("InstanceType", atrType)
    // updateATRForm("ContainerStatus", containerStatus)
  }

  if (!config) {
    if (externalClientData) {
      updateATRForm("ClientName", externalClientData[0])
      updateATRForm("EndToEndUId", externalClientData[1])
      updateATRForm("Company", externalClientData[2])
      updateATRForm("AvanadeCountry", externalClientData[3])
      updateATRForm("AccentureCountry", externalClientData[4])
      // updateATRForm("ChatGPT", "gpt-4")
      updateATRForm("InstanceType", history.location.state.InstanceType)
      updateATRForm("CAMPortalEnvironment", history.location.state.CAMPortalEnvironment)
      // updateATRForm("InstanceType", atrType)
      // updateATRForm("ContainerStatus", containerStatus)
    }
  }

  // const sapValue = document.getElementById("SAPContractID");
  // if (sapValue) {
  //   if (sapValue.value === "") {
  //     document.getElementById("clientNameDetails").style.display = "none";
  //     document.getElementById("endToEndIdDetails").style.display = "none";
  //     const verifiedIcon = document.getElementById("verifiedIcon")
  //     if (verifiedIcon) {
  //       verifiedIcon.style.display = "none";
  //     }
  //   } else {
  //     document.getElementById("clientNameDetails").style.display = "block";
  //     document.getElementById("endToEndIdDetails").style.display = "block";

  //   }
  // }

  if (!config) {
    if (isDialogFlow) {
      (isDialogFlowClientToken ||
        isDialogFlowDevToken) &&
        !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isWBSECodeMDIValid && isServiceNowTicketValid && isProjectNameValid && !errorADGroup && !errorSADGroup && !isADSADUnique
        ? setIsAllValid(true)
        : setIsAllValid(false);
    } else {
      !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isWBSECodeMDIValid && isServiceNowTicketValid && isProjectNameValid && !errorADGroup && !errorSADGroup && !isADSADUnique
        ? setIsAllValid(true)
        : setIsAllValid(false);
    }
  } else {
    (isServiceNowTicketValid ||
      isDialogFlowClientToken ||
      isDialogFlowDevToken) &&
      !isServiceEndDateInvalid && isWBSECodeValid && isWBSECodeMDIValid && isProjectNameValid && !errorADGroup && !errorSADGroup && !isADSADUnique
      ? setIsAllValid(true)
      : setIsAllValid(false);
  }

  // // change validation status
  // (isServiceNowTicketValid || 
  //   isWBSECodeValid || 
  //   isDialogFlowClientToken || 
  //   isDialogFlowDevToken) &&
  // !isServiceEndDateInvalid
  //   ? setIsAllValid(true)
  //   : setIsAllValid(false);


  // user can only input lower case letter and numbers in DomainContextRoot
  const handleChangeRoot = (event, fieldName) => {
    const re = /^[a-z0-9]*$/;
    const value = event.target.value;
    re.test(value) && updateATRForm(fieldName, value);
    setDomainContextRoot(value)
  };

  const handleChangeRootSuggestions = (event, value, fieldName) => {
    const re = /^[a-z0-9]*$/;
    // const value = event.target.value;
    re.test(value) && updateATRForm(fieldName, value);
    setDomainContextRoot(value)
  };

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateATRForm(fieldName, value);
  };

  // get user's input
  const handleChange = (event, fieldName) => {
    if (fieldName === "TentativeServiceEndDate") {
      updateATRForm(fieldName, event);
      getServiceEndDate(event);
    } else {
      const { value } = event.target;
      event.preventDefault();
      updateATRForm(fieldName, value);
      if (fieldName === "WBSEcode") {
        const wbsevalue = value.toUpperCase()
        if (wbsevalue.trim() === "CBYDQ001") {
          setIsWBSECodeMDIValid(false)
        }
        setIsWBSECodeValid(checkWBSECode(value));
      }
      if (fieldName === "ProjectName") {
        if (value.length > 32 || value.length < 3) {
          setIsProjectNameValid(false)
        } else {
          setIsProjectNameValid(true)
        }
      }
      if (fieldName === "RITM") {
        setIsServiceNowTicketValid(checkServiceNowTicket(value));
      }
      if (fieldName === "AdminDistributionGroup") {
        if (value == values.SuperAdminDistributionGroup) {
          setIsADSADUnique(true)
        } else {
          setIsADSADUnique(false)
        }
        if (value.includes(",") || value.includes(";") || value.includes(" ") || value.includes("@")) {
          setErrorADGroup(true)
        } else {
          setErrorADGroup(false)
        }
      }
      if (fieldName === "SuperAdminDistributionGroup") {
        if (value == values.AdminDistributionGroup) {
          setIsADSADUnique(true)
        } else {
          setIsADSADUnique(false)
        }
        if (value.includes(",") || value.includes(";") || value.includes(" ") || value.includes("@")) {
          setErrorSADGroup(true)
        } else {
          setErrorSADGroup(false)
        }
      }

      if (fieldName === "DialogFlowDevToken") {
        if (value.length > 0) {
          setIsDialogFlowDevToken(true);
        } else {
          setIsDialogFlowDevToken(false);
        }
      }
      if (fieldName === "DialogFlowClientToken") {
        if (value.length > 0) {
          setIsDialogFlowClientToken(true);
        } else {
          setIsDialogFlowClientToken(false);
        }
      }
      if (fieldName === "Environment") {
        getSelectedEnvironment(value);
      }
      if (fieldName === "InstanceType") {
        if (value == "Dedicated") {
        } else {
          updateATRForm("CAMPortalEnvironment", "Production");
        }
      }
      if (fieldName === "TentativeServiceEndDate") {
        getServiceEndDate(value);
      }
      if (fieldName === "RequestNotes") {
        if (value.length > 0 && value.length < 247) {
          getNotesValue({ emptyString: false, maxLength: false });
        } else if (value.length === 0) {
          getNotesValue({ emptyString: true, maxLength: false });
        } else if (value.length > 246) {
          getNotesValue({ emptyString: false, maxLength: true });
        }
      }
      if (fieldName === "SAPContractID") {
        let sapLength = value.length
        setSapContractCodeLength(sapLength)
        if (sapLength == 10) {
          fetchExternalClientDetails(value)
        }
      }
      if (fieldName === "OpenAI") {
        if (instanceType === "Dedicated") {
          if (event.target.checked === false) {
            updateATRForm(fieldName, "False");
          } else {
            updateATRForm(fieldName, "True");
          }
        } else {
          updateATRForm(fieldName, value);
        }
      }
      if (fieldName === "DeploymentRegion") {
        if (value == "sydney") {
          setRegionCode("dh")
        } else if (value == "virginia") {
          setRegionCode("dh1")
        } else if (value == "frankfurt") {
          setRegionCode("dh2")
        } else if (value == "singapore") {
          setRegionCode("dh3")
        } else if (value == "tokyo") {
          setRegionCode("dh4")
        } else if (value == "ireland") {
          setRegionCode("dh5")
        } else if (value == "london") {
          setRegionCode("dh6")
        } else {
          setRegionCode("[__]")
        }
      }
      if (fieldName === "ChatGPT") {
        updateATRForm(fieldName, value);
        setGptVersion(value);
      }
    }
  };

  updateATRForm("ConfigurationType", configurationType);



  // it would check whether the root has existed from DB once user stop typing longer than 0.3s
  const debouncedValue = useDebounce(domainContextRoot, 300);
  useEffect(() => {
    debouncedValue && checkDomainAvaliable(debouncedValue);
  }, [debouncedValue]);

  return (
    <>
      <GeneralForm
        FormId={0}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              {!config &&
                <Grid item xs={4}>
                  {PlatformFormField.SAPContractIDPlatformField(values, handleChange, externalClientError, isExternalClientLoading, sapContractCodeLength)}
                </Grid>}

              {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !isExternalClientLoading && !externalClientError
                ? !config &&
                <Grid item xs={4}> {PlatformFormField.ClientNamePlatformField(values, handleChange, externalClientData[0])}</Grid>
                : ""
              }


              {sapContractCodeLength == 10 && !isExternalClientLoading && !externalClientError ?
                !config &&
                <Grid item xs={4}> {PlatformFormField.EndToEndUIdPlatformField(values, handleChange, externalClientData[1])}</Grid>
                : ""
              }

              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
              </Grid >
              {!config &&
                <Grid item xs={4}>
                  {FormField.ServiceNowTicket(handleChange, values, isServiceNowTicketValid)}
                </Grid>}
              <Grid item xs={4}>
                {FormField.SecondaryContact(handleChange, values, config)}
              </Grid>

              <Grid item xs={12}>
                {PlatformFormField.RequestNotesPlatformField(
                  values,
                  handleChange,
                  notesValidations
                )}
              </Grid>
            </Grid>
          </div>
        }
        EnvironmentFields={
          <div>
            {!config ? <p> Expected Custom URL: <span style={{ color: " #0041F0" }}>https://{regionCode}.{domainContextRoot}.mywizard-aiops.com</span></p> : ""}
            {!config && isEnableQuasar ? <p> Expected Quasar Custom URL: <span style={{ color: "#0041F0" }}>https://{regionCode}-{domainContextRoot}quasar.mywizard-aiops.com</span></p> : ""}
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  {PlatformFormField.GDPRPlatformField(
                    values,
                    handleChange,
                    isGDPR,
                    classes
                  )}
                </Grid>
                <Grid item xs={3}>
                  {PlatformFormField.DeploymentRegionATRPlatformField(
                    values,
                    handleChange,
                    isGDPR,
                    isDedicated,
                    isOpenAI,
                    instanceType
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!config ? PlatformFormField.DomainContextRootPlatformFieldClient(
                    values,
                    handleChangeRootSuggestions,
                    // handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    rootWrongFormatHint,
                    externalClientData,
                    domainContextRoot,
                    isLoading
                  ) : PlatformFormField.DomainContextRootPlatformField(
                    values,
                    handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    isLoading
                  )}
                </Grid>
                <Grid item xs={3}>
                  {PlatformFormField.EnvironmentPlatformField(values, handleChange)}
                </Grid>
                {values.Environment === "Production" &&
                  <Grid item xs={3}> {PlatformFormField.AtrTierPlatformField(values, handleChange)}</Grid>}
              </Grid>
            </div>
          </div>
        }

        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              {/* <Grid item xs={6}>
                {PlatformFormField.InstanceTypePlatformField(
                  values,
                  handleChange,
                  isDedicated,
                  classes
                )}
              </Grid> */}
              <Grid item xs={4}>
                {FormField.DialogFlowField(handleChange, values, isDialogFlow)}
              </Grid>
              <Grid item xs={4}>
                {FormField.AdminDistributionGroupFieldATR(handleChange, values, errorADGroup, isADSADUnique)}
              </Grid>
              <Grid item xs={4}>
                <StyledTooltip
                  title={longTextEndDate}
                  placement="left-start"
                  noValidate
                >
                  <DateTextFieldCommonComponent
                    tentativeServiceEndDate={values.TentativeServiceEndDate}
                    selectedEnvironment={selectedEnvironment}
                    isServiceEndDateInvalid={isServiceEndDateInvalid}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleChange={handleChange}
                  />
                </StyledTooltip>
              </Grid>
              {instanceType === "Dedicated" ? <Grid item xs={4}>
                {FormField.OpenAIFieldATR(handleChange, values, isOpenAI)}
              </Grid> :
                <Grid item xs={4}>
                  {FormField.GenwizardIntegrationField(handleChange, values)}
                </Grid>}
              {values.OpenAI === "OpenAI+Quasar" && <>
                  <Grid item xs={4}> {FormField.OpenAITextEmbeddingsModel(handleChange, values)}</Grid>
                  <Grid item xs={4}> {FormField.SuperAdminDistributionGroupField(handleChange, values, errorSADGroup, isADSADUnique)}</Grid>
                </>
              }

              {/* {isOpenAI || isEnableQuasar ? */}
              {/* <Grid item xs={3}> {FormField.ChatGPTFieldForATR(handleChange, values)}</Grid> : ""} */}
              {instanceType === "Dedicated" ? <Grid item xs={4}>
                {isOpenAI ? FormField.ChatGPTFieldForQuasar(handleChange, values) : ""}
              </Grid> : ""
              }
              {values.OpenAI === "OpenAI+Quasar" &&
                <Grid item xs={4}> {FormField.OpenAITextEmbeddingsModel(handleChange, values)}</Grid>}
              {instanceType === "Dedicated" ? <Grid item xs={4}>
                {isOpenAI ? FormField.OpenAITextEmbeddingsModel(handleChange, values) : ""}
              </Grid> : ""
              }
              {
                isOpenAI ? <Grid item xs={4}>{FormField.SuperAdminDistributionGroupField(handleChange, values, errorSADGroup, isADSADUnique)}</Grid>
                  : ""
              }

              {/* <Grid item xs={6}> */}
              {/* {isOpenAI && gptVersion && PlatformFormField.DeploymentRegionFieldForATR(handleChange, values, gptVersion, isGDPR, isDedicated)} */}
              {/* {PlatformFormField.DeploymentRegionATRPlatformField(
                  values,
                  handleChange,
                  isGDPR,
                  isDedicated
                )} */}
              {/* </Grid> */}
              {/* if user select enable in DialogFlow, it would show DialogFlowDevToken with default value */}
              {isDialogFlow &&
                <Grid item xs={6}>{FormField.DialogFlowDevTokenField(handleChange, values)}</Grid>}
              {isDialogFlow &&
                <Grid item xs={6}>{FormField.DialogFlowClientTokenField(handleChange, values)}</Grid>}
              {/* <Grid item xs={6}>
                {FormField.AdminDistributionGroupFieldATR(handleChange, values, errorADGroup)}
              </Grid> */}
              {/* <Grid item xs={6}>
                <StyledTooltip
                  title={longTextEndDate}
                  placement="left-start"
                  noValidate
                >
                  <DateTextFieldCommonComponent
                    tentativeServiceEndDate={values.TentativeServiceEndDate}
                    selectedEnvironment={selectedEnvironment}
                    isServiceEndDateInvalid={isServiceEndDateInvalid}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleChange={handleChange}
                  />
                </StyledTooltip>
              </Grid> */}
            </Grid>
          </div>
        }
        LeftColumnFields={
          <div>
          </div>
        }
        RightColumnFields={
          <div>
          </div>
        }
        history={history}
      ></GeneralForm>
    </>
  );
};

const MSTP = (state, ownProps) => ({
  values: state.fui.ATRForm,
  isRootExist: state.fui.isRootExist,
  configurationType: state.fui.configurationType,
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  externalClientData: state.fui.externalClientData,
  externalClientError: state.fui.externalClientError,
  isExternalClientLoading: state.fui.isExternalClientLoading,
  isLoading: state.fui.isLoading,
});

export default connect(MSTP, {
  checkDomainAvaliable: checkRootExisted,
  updateATRForm: fuiRedux.updateATRForm,
  fetchExternalClientDetails,
})(withRouter(AutomaticTicketResolver));
